#home {
    /* height: 180vh; */
    /* padding-top: 7rem; */
    /* overflow: hidden; */
}

.header__container {
    text-align: center;
    height: 100%;
    /* position: relative; */
}

.contact {
    margin-top: 2.5rem;  /*to push buttons littlebit down*/
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}