*,
*::before,
*::after {
  box-sizing: border-box;
}

.body__class {
  margin-top: 7%;
  /* font-family: Arial, Helvetica, sans-serif; */
}

.timeline {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 4% auto;
  padding: 15px 0;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  background: var(--color-primary);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
}

.container {
  padding: 15px 30px;
  position: relative;
  background: inherit;
  width: 50%;
}

.container.left {
  left: 0;
}

.container.right {
  left: 50%;
}

.container::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  right: -8px;
  background: #ffffff;
  border: 2px solid var(--color-primary);
  border-radius: 16px;
  z-index: 1;
}

.container.right::after {
  left: -8px;
}

.container::before {
  content: '';
  position: absolute;
  width: 50px;
  height: 2px;
  top: calc(50% - 1px);
  right: 8px;
  background: var(--color-primary);
  z-index: 1;
}

.container.right::before {
  left: 8px;
}

.container .date {
  position: absolute;
  display: inline-block;
  top: calc(50% - 8px);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: var(--color-primary);
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;
}

.container.left .date {
  right: -33%;
}

.container.right .date {
  left: -33%;
}

.container .icon {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 6px 0;
  top: calc(50% - 20px);
  /* background: var(--color-bg-variant); */
  border: 2px solid var(--color-primary);
  border-radius: 40px;
  text-align: center;
  font-size: 18px;
  color: var(--color-primary);
  z-index: 1;
}

.container.left .icon {
  right: 56px;
}

.container.right .icon {
  left: 56px;
}

.container .content {
  padding: 30px 90px 30px 30px;
  background: var(--color-bg-variant);
  position: relative;
  border-radius: 0 500px 500px 0;
}

.container .content:hover {
    border: 1px solid var(--color-primary);
    background: transparent;
    cursor: default;
}

/* .container .content:hover + .container .icon{
    background:transparent;
} */
.work__history {
    color: var(--color-light);
}

.container.right .content {
  padding: 30px 30px 30px 90px;
  border-radius: 500px 0 0 500px;
}

.container .content h2 {
  margin: 0 0 0 0;
  font-size: 18px;
  font-weight: normal;
  color: var(--color-primary);
}

.container .content p {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
}

.experience__akamLogo {
  height: 25%;
  width: 25%;
  margin-bottom: -0.7rem;
}

.experience__capgLogo {
  height: 60%;
  width: 60%;
  margin-bottom: -0.5rem;
}

@media (max-width: 767.98px) {
  .timeline::after {
    left: 90px;
  }

  .container {
    width: 100%;
    padding-left: 120px;
    padding-right: 30px;
  }

  .container.right {
    left: 0%;
  }

  .container.left::after, 
  .container.right::after {
    left: 82px;
  }

  .container.left::before,
  .container.right::before {
    left: 100px;
    border-color: transparent var(--color-primary) transparent transparent;
  }

  .container.left .date,
  .container.right .date {
    right: auto;
    left: 15px;
    max-width: 20px;
    margin-top: -50px;
  }

  .container.left .icon,
  .container.right .icon {
    right: auto;
    left: 146px;
  }

  .container.left .content,
  .container.right .content {
    padding: 30px 30px 30px 90px;
    /* border-radius: 500px 0 0 500px; */
    border-radius: 30px;
  }

  .experience__akamLogo {
    height: 50%;
    width: 50%;
  }

  .experience__capgLogo {
    height: 100%;
    width: 100%;
  }
}

.experience__header {
    color: var(--color-primary);
}

.job__position {
    color: var(--color-light);
    margin: 0 0 10px 0;
}
