h1, h2, h3, h4, h5 {
    text-align: center;
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6.5rem;
}

.about__description {
    text-align: center;
}

.about__me {
    color: var(--color-primary);
}

.about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: .6rem;
    text-align: center;
    transition: var(--transition);
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary);
    cursor: default;
}

.about__card h5 {
    font-size: 0.95rem;
}

.about__card small {
    font-size: 1rem;
    color: var(--color-light);
}

.about__container {
    width: 75%;
    margin: 0 auto; 
}

.about__content p{

    margin: 2rem 0 2.6rem;
    color: var(--color-light);
}

/* Media queries for medium devices(Tablets) */

@media screen and (max-width: 1024px) {

    .about__container{

        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__me{

        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about__content p{

        margin: 1rem 0 1.5rem;
    }


}

/* Media queries for small devices(Mobiles) */

@media screen and (max-width: 600px) {

    .about__me{

        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__cards{

        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .about__card {
        width: 80%;
        margin: 0 auto 0;
    }

    .about__content{

        text-align: center;
    }

    .about__content p{

        margin: 1.5rem 0;
    }
}